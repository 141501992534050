const FORM_SELECTOR = '.js-blog-form'
class SiteFooter extends HTMLElement {
  constructor () {
    super()
    const formEl = this.querySelector(FORM_SELECTOR)

    if (formEl) {
      const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            const input = formEl.querySelector('input[type="email"]')
            if (!input) {
              return
            }
            const placeholderText = input.attributes.placeholder.nodeValue
            if (placeholderText && !this.floatLabelAppended) {
              const placeholderEl = document.createElement('label')
              placeholderEl.classList.add('klaviyo-input-label')
              placeholderEl.innerText = placeholderText
              input.parentNode.append(placeholderEl)
              input.setAttribute('placeholder', ' ')
              this.floatLabelAppended = true
            }
          }
        }
      }
      const observer = new MutationObserver(callback)
      observer.observe(formEl, { attributes: true, childList: true, subtree: true })
    }
  }

  floatLabelAppended = false

  scrollToTop () {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}

customElements.define('site-footer', SiteFooter)
